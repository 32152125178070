/* Глобальні стилі */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Повністю розтягуємо сайт */
html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Вимикаємо горизонтальний скрол */
    font-family: 'SourceSansPro';
    background: radial-gradient(circle at center,
    #F222A9 0%,
    #05AFF2 40%,
    #77F289 70%,
    #BBF2BE 100%);
    background-size: 400% 400%;
    animation: softClouds 20s infinite alternate ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

/* Анімація плавного переливання */
@keyframes softClouds {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

/* Фіксуємо контейнер сайту */
.app {
    width: 100%;
    /*max-width: 1400px; !* Щоб контент не був занадто широким *!*/
    padding: 20px;
}

/* Навігаційне меню */
.navbar {
    border-radius: 33px;
    width: 95%;
    background: rgba(102, 51, 153, 0);
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width:1200px
}

.navbar .logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.logoty {
    width: 100px;
    height: 40px;
    background-image: url('components/img/ML1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.navbar .menu {
    display: flex;
    gap: 20px;
}

.navbar .menu a {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #242424;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

}


.navbar {
    display: flex;
    align-items: center;
    justify-content: center; /* Центрує все */
    position: relative; /* Для позиціонування меню */
    /*background: linear-gradient(90deg, rgb(179, 229, 159);*/
    /*background: orangered;*/
    padding: 10px 20px;
    border-radius: 30px;
    /*max-width: 90%;*/
    margin: 0 auto;
    height: 60px; /* Встановлюємо висоту */
}

.logoty {
    width: 170px;
    height: 40px;
    background-image: url('components/img/ML1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute; /* Абсолютне центрування */
    left: 81px;
    transform: translateX(-50%);
}

.menu {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 20px; /* Меню справа */
}

.menu a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: 0.3s;
}

.menu a:hover {
    color: #007bff;
}

/* Поле введення промпту */
.prompt-container {
    display: flex;
    align-items: center;
    background: rgba(200, 200, 200, 0.3);
    border-radius: 30px;
    padding: 12px 20px;
    width: 60%;
    max-width: 800px;
    margin: 30px auto;
    backdrop-filter: blur(10px);
}

.prompt-container input {
    width: 94%;
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: black;
    padding: 8px;
}

.prompt-container input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

/* Прибираємо зайвий фон у 'Your Creations' */
.video-list_new h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: black;
}

/* Сітка з відео */
.video-grid_new {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto; /* Додаємо горизонтальний скрол */
    scroll-behavior: smooth;
}

/* 🌍 Адаптивність для мобільних пристроїв */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        text-align: center;
        padding: 15px;
    }

    .navbar .menu {
        /*flex-direction: column;*/
        gap: 10px;
    }

    .prompt-container {
        width: 90%;
        flex-direction: column;
        gap: 10px;
    }
}


#root {
    width: 100%;
}

body {
    margin: 0;
    background: rgba(79, 60, 53, 0) !important;
    overflow: hidden;
}

.content {
    height: 78% !important;
}

.video-loading_new {
    width: 100%;
}

/* Загальний контейнер */
.video-list_new {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    text-align: center;
}

/* Заголовок */
.video-list_new h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

/* Сітка відео */
.video-grid_new {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 відео в ряд */
    gap: 20px;
    justify-content: center;
}

/* Відео-картка */
.video-card_new {
    background: white;
    /*padding: 10px;*/
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    aspect-ratio: 9 / 16; /* Формат відео TikTok */
}

/*.video-card_new:hover {*/
/*    transform: translateY(-5px);*/
/*    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);*/
/*}*/

/* Стилі для відео */
.video-wrapper_new {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 10px;
    overflow: hidden;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

/* Текстова інформація */
.video-info_new {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #444;
}

/* Анімація завантаження */
/* Анімація завантаження */
.video-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    background: radial-gradient(circle, rgba(255, 228, 196, 0.8) 0%, rgba(255, 192, 203, 0.5) 50%, rgba(255, 255, 224, 0.2) 100%);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    animation: backgroundTransition 20s infinite ease-in-out;
}

.video-loading::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, rgba(255, 182, 193, 0.8) 0%, rgba(173, 216, 230, 0.6) 50%, rgba(240, 248, 255, 0.2) 100%);
    animation: rotateFog 8s infinite linear, colorPulse 15s infinite ease-in-out;
    transform: translate(-50%, -50%) rotate(0deg);
    border-radius: 50%;
    opacity: 0.9;
}


/* 🌍 Адаптивність */
@media (max-width: 1024px) {
    .video-grid_new {
        grid-template-columns: repeat(2, 1fr); /* 2 відео в ряд на планшетах */
    }
}

@media (max-width: 768px) {
    .video-grid_new {
        grid-template-columns: 1fr; /* 1 відео на рядок на мобільних */
        gap: 1px
    }

    .video-card_new {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }


}


#root {
    overflow-y: auto;
}


/* Фонова розмитість при відкритті відео */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px); /* Робимо розмиття */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

/* Контейнер для відео */
.modal-content {
    position: relative;
    width: 80%;
    max-width: 900px;
    height: 80%;
    max-height: 700px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slideIn 0.3s ease-in-out;
}

/* Закриваюча кнопка */
.close-button {
    z-index: 99;
    position: absolute;
    top: 15px;
    right: 20px;
    background: transparent;
    color: white;
    font-size: 24px;
    border: none;
    cursor: pointer;
}

/* Стилі для кнопки завантаження відео */
.download-button {
    z-index: 99;
    position: absolute;
    top: 15px;
    right: 89%;
    width:  40px;  /* Розмір кнопки */
    height: 40px;
    background: url('components/img/dowlauds.png') no-repeat center center;
    background-size: contain;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0.8;
}

/* Ефект при наведенні на кнопку */
.download-button:hover {
    transform: scale(1.1);
    opacity: 1;
}

/* Додатковий стиль для адаптивності */
@media (max-width: 768px) {
    .download-button {
        width: 31px;
        height: 40px;
        top: -47px;
        right: 89%;
    }
    .close-button {
        top: -35px;
    }
}

/* Відео на весь екран */
.fullscreen-video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

/* Анімація відкриття */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}


/* Контейнер для відео */
.video-wrapper_new {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 10px;
    overflow: hidden;
}

/* Опис відео, який буде прямо на відео */
.video-description {
    position: absolute;
    bottom: 10%;
    left: 46%;
    transform: translateX(-50%);
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

/* Коли відео починає грати – текст зникає */
.video-wrapper_new .react-player-playing + .video-description {
    opacity: 0;
}


.video-description > p {
    position: absolute;
    bottom: 10%; /* Відступ від низу */
    width: 100%; /* Робимо ширшим, щоб добре виглядало */
    text-align: left; /* Вирівнюємо текст по лівому краю */
    font-size: 45px; /* Великий розмір шрифту */
    font-weight: bold;
    text-transform: uppercase; /* Всі літери великі */
    color: white; /* Білий текст */
    background: rgba(0, 0, 0, 0.5); /* Напівпрозорий фон */
    padding: 10px 15px;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out; /* Анімація зникнення */
    font-family: 'Source Sans Pro';
}

/* Коли відео запускається – опис зникає */
.video-card_new.playing .video-description {
    opacity: 0;
}


@font-face {
    font-family: 'SourceSansPro';
    src: url('fonts/SourceSansPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('fonts/SourceSansPro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

.video-description {
    font-family: 'SourceSansPro', sans-serif;
    font-size: 45px;
    text-align: left;
    color: white;
}


/* === Основний контейнер випадаючих списків === */
.mini-dropdown-container {
    display: flex;
    gap: 12px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

/* === Кожен окремий випадаючий список === */
.mini-dropdown {
    position: relative;
    display: inline-block;
}

/* === Кнопка вибору === */
.mini-dropdown-button {
    background: white;
    border: 2px solid #ddd;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

/* === Додаємо іконку-стрілочку ▼ === */
.mini-dropdown-button::after {
    content: " ▼";
    font-size: 12px;
    margin-left: 8px;
    transition: transform 0.3s ease;
}

/* === Анімація відкриття стрілочки при наведенні === */
.mini-dropdown:hover .mini-dropdown-button::after {
    transform: rotate(180deg);
}

/* === Стилі випадаючого списку === */
.mini-dropdown-content {
    display: none;
    position: absolute;
    background: white;
    min-width: 160px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1000000;
    overflow: hidden;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    animation: fadeIn 0.2s ease-in-out;
}
.mini-dropdown {
    position: relative;
}
.react-player iframe {
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.mini-dropdown {
    position: relative;
}

/* === Відображення меню при наведенні === */
.mini-dropdown:hover .mini-dropdown-content {
    display: flex;
}


/* === Опції у випадаючому списку === */
.mini-dropdown-content p {
    padding: 10px 5px;
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    transition: background 0.2s ease-in-out;
}

/* === Наведення на пункт меню === */
.mini-dropdown-content p:hover {
    background: #f1f1f1;
}

/* === Анімація появи === */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* === Поле введення промпту + кнопка відправки === */
.prompt-container {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    padding: 10px 20px;
    width: 60%;
    max-width: 800px;
    margin: 30px auto;
    backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 255, 255, 0.3);
}

/* === Поле введення === */
.prompt-container input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: black;
    padding: 8px;
}

/* === Плейсхолдер текст === */
.prompt-container input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

/* === Кнопка відправки === */
.send-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
}

/* === SVG Іконка відправки (магічна паличка) === */
.send-button svg {
    width: 24px;
    height: 24px;
    stroke: black;
    transition: transform 0.2s ease-in-out;
}

/* === Анімація кнопки відправки === */
.send-button:hover svg {
    transform: scale(1.1);
}

/* === Адаптація для мобільних === */
@media (max-width: 768px) {
    .mini-dropdown-container {
        /*flex-direction: column;*/
        align-items: center;
    }

    .prompt-container {
        width: 90%;
        flex-direction: column;
        gap: 10px;
    }

    .mini-dropdown-content {
        min-width: 140px;
    }
}


.prompt-input-wrapper {
    width: 100%;
}

.prompt-container {
    display: flex;
    flex-direction: column;
}


/* Сітка відео: Завжди 3 відео в ряд */
.video-grid_new {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 відео в ряд на всіх пристроях */
    gap: 20px;
    justify-content: center;
}

/* Адаптивність: Навіть на мобільних пристроях залишаємо 3 відео в ряд */
@media (max-width: 1024px) {
    .video-grid_new {
        grid-template-columns: repeat(3, 1fr);
    }
}


/* Відео-картка */
.video-card_new {
    background: white;
    /*padding: 10px;*/
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    aspect-ratio: 9 / 16; /* Формат відео TikTok */
}

/* Анімація при наведенні */
/*.video-card_new:hover {*/
/*    transform: translateY(-5px);*/
/*    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);*/
/*}*/

/* Відео в межах картки */
.video-wrapper_new {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 10px;
    overflow: hidden;
}

/* Контейнер для відео */
.video-card_new video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .logoty {
        width: 110px;
    }

    .video-grid_new {
        grid-template-columns: repeat(3, 1fr);
    }

    .video-grid_new {
        gap: 1px;
    }

    .video-card_new {
        padding: 0px;
    }

    #root {
        width: 107%;
    }

    .video-description > p {
        font-size: 15px;
    }

    .video-description {
        padding: 7px 5px;
    }

    .video-card_new {
        border-radius: 0px;
    }

    .video-card_new video {
        border-radius: 0px;

    }

    .video-wrapper_new {
        border-radius: 0px;
    }

    .prompt-container input {
        width: 94%;
    }
}

@media (max-width: 480px) {
    .logoty {
        left: 20%;
    }

    .navbar .menu {
        flex-direction: column;
        gap: 10px;
    }

    .prompt-container input {
        width: 89%;
    }

    .logoty {
        width: 100px;
    }

    .video-grid_new {
        grid-template-columns: repeat(3, 1fr);
    }

    #root {
        width: 107%;
    }

    .video-description {
        padding: 7px 5px;
    }

    .video-card_new {
        border-radius: 0px;
    }

    .video-card_new video {
        border-radius: 0px;
    }

    .video-wrapper_new {
        border-radius: 0px;
    }
}

.mini-dropdown-content > p {
    color: #2e856e;
}
.modal-content_limit {
       height: 288px;

}

/* ======= Стилі для затемненого фону ======= */
.modal_limit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.6); !* Затемнення заднього фону *!*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

/* ======= Контейнер модального вікна ======= */
.modal-content_limit {
    position: relative;
    background: #ffffff;
    padding: 25px;
    border-radius: 12px;
    text-align: center;
    width: 420px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4); /* Чітка тінь */
    animation: popUp 0.3s ease-in-out;
    border: 2px solid #ffcc00; /* Жовта рамка */
}

/* ======= Хрестик для закриття ======= */
.modal-close_limit {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
}

.modal-close_limit:hover {
    color: #d9534f; /* Червоний колір при наведенні */
}

.modal-close_limit {
    z-index: 99;
    color: #d9534f!important; /* Червоний колір при наведенні */
}

/* ======= Заголовок ======= */
.modal-content_limit h2 {
    color: #d9534f;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content_limit h2::before {
    content: "⚠️";
    font-size: 24px;
    margin-right: 8px;
}

/* ======= Текст у модальному вікні ======= */
.modal-content_limit p {
    font-size: 16px;
    color: #444;
    margin-bottom: 15px;
    line-height: 1.5;
}

/* ======= Кнопка OK ======= */
.close-button_limit {
    margin-top: 10px;
    padding: 10px 18px;
    border: none;
    background: #007BFF;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 6px;
    transition: background 0.3s;
}

.close-button_limit {
    background: #0056b3;
}


.close-button_limit:hover {
    background: #0056b3;
}

/* ======= Анімації ======= */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes popUp {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.video-card_new {
    background: white;
    /*padding: 10px;*/
    border-radius: 15px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.8); /* Тінь тільки знизу */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    aspect-ratio: 9 / 16; /* Формат відео TikTok */
}

.all_input {
    display: flex;
    border-radius: 30px;
    padding: 0px 10px;
    width: 60%;
    max-width: 800px;
    margin: 10px auto;
    /*-webkit-backdrop-filter: blur(8px);*/
    backdrop-filter: blur(8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    flex-direction: column;
}

.rounded-select {
    border-radius: 20px !important;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.63);
}

.prompt-input-wrapper {
    margin-top: 2px;
}


@media (max-width: 768px) {
    .all_input {
        width: 100%;
    }
}

@font-face {
  font-family: "MyCustomFont";
  src: url("fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


.video-description p {
  font-family: "MyCustomFont", sans-serif;
  font-size: 30px;
  color: #ffffff;
}

.video-description {
    position: absolute;
    bottom: 0; /* Фіксуємо блок внизу */
    left: 0;
    width: 100%; /* Розтягуємо на всю ширину */
    /*background: rgba(0, 0, 0, 0.6); !* Темний прозорий фон *!*/
    padding: 10px 15px;
    border-radius: 0 0 8px 8px; /* Заокруглення лише знизу */
    font-family: "MyCustomFont", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-description {
    margin-left: 50%;
}

/* Адаптація для мобільних */
@media (max-width: 768px) {
    .video-description {
        font-size: 16px;
        padding: 8px;
    }
    .video-description p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .video-description {
        font-size: 14px;
        padding: 6px;
    }
    .video-description p {
        font-size: 14px;
    }
}

.video-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-description > p {
  margin: 0;
  width: 100%;
  padding: 20% 15px;
  background: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(128, 128, 128, 0.04) 100%);
  border-radius: 0 0 8px 8px; /* Заокруглення лише внизу */
  font-family: "MyCustomFont", sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
}

.subscribe-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(230, 151, 59, 0.47); /* Оранжевий фон */
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.subscribe-button:hover {
  background-color: #d28432;
}

.subscribe-icon {
  font-size: 18px;
}

.cloce_logoty {
    display: none;
}

.close-menu {
    display: none;
}
@media (max-width: 1024px) {
    .close-menu {
    display: block;
}
    .cloce_logoty {
    display: block;
}
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #e6973b;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1000;
        border-radius: 0;
    }

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 999;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        padding-top: 20px; /* Зменшений відступ зверху */
    }

    .menu.active {
        transform: translateY(0);
    }

    /* Логотип у меню */
    .menu-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        position: absolute;
        top: 0;
        left: 0;
        height: 60px; /* Фіксована висота */
        background: white;
    }

    .logoty {
        width: 140px;
        height: 40px;
        background-image: url('components/img/ML1.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Хрестик для закриття */
    .close-menu {
        margin-left: 95%;
        font-size: 30px;
        color: black;
        cursor: pointer;
        font-weight: bold;
        transition: color 0.3s;
    }

    .close-menu:hover {
        color: #e6973b;
    }

    /* Посилання у меню */
    .menu a {
        font-size: 24px;
        color: black;
        margin: 15px 0;
        text-decoration: none;
    }

    .menu a:hover {
        color: #e6973b;
    }

    /* Бургер-кнопка */
    .burger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 24px;
        cursor: pointer;
    }

    .burger div {
        width: 100%;
        height: 3px;
        background: black;
        transition: all 0.3s ease-in-out;
    }

    .burger.open div:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .burger.open div:nth-child(2) {
        opacity: 0;
    }

    .burger.open div:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }
    .burger {
        margin-left: 95%;
    }
}



@media (max-width: 1024px) {
    .terms-container {
        background: rgba(236, 236, 236, 0.63);
        padding: 20px;
    }
    .content {
        padding: 50px 26px;
    }
}

@media (min-width: 1024px) {
    .terms-container {
        background: rgba(236, 236, 236, 0.63);
        width: 1000px;
        padding: 20px;
    }

}



@media (max-width: 1024px) {
.video-description > p {
    font-size: 25px;
    padding: 15% 6px;
}

}

    .modal-content_limit p {
    font-size: 32px;
    }

@media (max-width: 720px) {
.video-description > p {
    font-size: 15px;
    padding: 15% 6px;
}

    .modal-content_limit p {
    font-size: 20px;
    }

}
button:hover {
    background-color: rgba(124, 245, 10, 0.73);
}
