/* Загальний стиль футера */
.footer {
    background: #242424;
    color: white;
    padding: 2.5rem 0;
    width: 100%;
    margin-top: auto;
    text-align: center;
        margin-top: auto; /* Футер притискається донизу */

}
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Висота на весь екран */
}
/* Контейнер футера */
.footer-container {
    display: flex;
    flex-wrap: wrap; /* Гнучке розташування для адаптивності */
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
}

/* Загальні стилі секцій */
.footer-section {
    flex: 1;
    min-width: 220px;
}

/* Логотип */
.footer-logo {
    background-image: url('img/wite_logo.png');
    width: 170px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 1rem;
}

/* Опис */
.footer-description {
    font-size: 0.875rem;
    opacity: 0.8;
    line-height: 1.5;
}

/* Копірайт */
.footer-created {
    font-size: 0.875rem;
    opacity: 0.6;
    margin-top: 1rem;
}

/* Навігація */
.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin: 0.5rem 0;
}

.footer-links a {
    font-size: 0.875rem;
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #ffeb3b;
    text-decoration: underline;
}

/* Соціальні іконки */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.social-icons a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #ffeb3b;
}

/* Кнопки футера */
.buttons-section {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

.footer-button {
    background: #ffb400;
    color: #242424;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    white-space: nowrap;
}

.footer-button:hover {
    background: #ffca28;
}


.footer-section > p {
        text-align: left;

}
/* Адаптивність */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
    }
    .footer-section {
        width: 100%;
    }
    .footer-logo {
        width: 100%;
    }
    .footer-section > p {
        text-align: center;
}


    .buttons-section {
        flex-direction: column;
        gap: 0.75rem;
    }

    .footer-button {
        width: 100%;
    }
    .footer {
        text-align: left;
    }
}


/* Загальний стиль футера */
.footer {
    background: #242424;
    color: white;
    padding: 2rem 0;
    width: 100%;
    text-align: center;
    position: relative; /* Забезпечує коректне розташування */
}

/* Основний контейнер */
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Робить сторінку на всю висоту екрану */
}

/* Контент */
.content {
    flex: 1; /* Розтягує контентний блок, щоб футер залишався внизу */
}

/* Контейнер футера */
.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
}

/* Кнопки футера */
.buttons-section {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

/* Фіксує футер внизу, якщо контенту мало */
.footer-fixed {
    margin-top: auto;
}
.app {
    padding: 0px;
}


