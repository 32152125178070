body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #6E3D9B;
}

.login-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.login-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/login.jpeg');
    background-size: cover;
    background-position: 50%;
    filter: blur(2px);
    z-index: -1;
}


.login-form-container {
  z-index: 9999;
  width: 400px;
  padding: 40px;
  background: rgba(107, 129, 225, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

h2 {
  color: white;
  margin-bottom: 20px;
}

.ant-form {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-input,
.ant-input-password {
  border-radius: 30px;
  padding: 10px 15px;
}

.forgot-password {
  display: block;
  text-align: left;
  color: white;
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  background: #1a237e;
  border: none;
}

.social-login {
  margin-top: 20px;
  text-align: center;
  color: white;
}

.social-buttons {
  z-index: 999999;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.social-buttons .ant-btn {
  width: 48%;
  border-radius: 30px;
  background: white;
  color: #333;
}

.register-link {
  z-index: 9999;
  margin-top: 20px;
  color: white;
  text-align: center;
}

.register-link a {
  color: #4fc3f7;
}

.butterfly {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}

.butterfly-1 {
  width: 200px;
  height: 200px;
  top: -50px;
  left: -30px;
  transform: rotate(-15deg);
  background-image: url('../img/but1.png');
}

.butterfly-2 {
  transform: scale(0.5); /* Зменшить до 50% від початкового розміру */
  top: -30px;
  right: -20px;
  background-image: url('../img/but2.png');
}

/* Адаптивність для менших екранів */
@media (max-width: 768px) {
  .login-form-container {
    width: 90%;
    padding: 20px;
  }

  .butterfly-1 {
    width: 150px;
    height: 150px;
    top: -40px;
    left: -20px;
  }

  .butterfly-2 {
    width: 100px;
    height: 100px;
    top: -20px;
    right: -10px;
  }
}

.content {
  margin-top: 45px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
}

.login-box {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-box h1 {
  font-size: 36px;
}

.login-box p {
  color: #6E3D9B;
}

.login-box input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.remember-me {
  display: flex;
  justify-content: space-between;
}


.login-form-button {
  background-color: #F4B400;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.create-account a {
  color: #5844F4;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: transparent;
}

.footer p {
  text-align: center;
  flex: 1;
  color: white;
  margin: 0;
}
.mirror-image {
  transform: scaleX(-1);  /* Дзеркальне відображення дівчинки */
}


.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: transparent;
}

.img-left {
  max-width: 20%; /* Задано максимальну ширину для зображення зліва */
}

.img-right {
  position: absolute;  /* Позиціонує зображення */
  bottom: 0;           /* Прив'язка до низу сторінки */
  right: 10px;         /* Прив'язка до правої частини */
  max-width: 20%;      /* Задає максимальну ширину 20% */
  height: auto;        /* Висота автоматично підлаштовується */
}

.mirror-image {
  transform: scaleX(-1);  /* Дзеркальне відображення дівчинки */
}
.contentTest {
display: flex;
width: 337px;
height: 131px;
padding: 0px 6.5px 0px 7.5px;
flex-direction: column;
justify-content: center;
align-items: center;
/*gap: 34px;*/
flex-shrink: 0;
    margin-top: 10%;
}


/* Загальні стилі для контейнера */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /*background-color: #5D3FD3; !* Темно-фіолетовий фон *!*/
}

.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h4 {
  color: #333;
  margin-bottom: 5px;
}

/* Стилі для інпутів */
.input-field {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.input-field:focus {
  border-color: #ff9900;
  outline: none;
  box-shadow: 0 0 5px rgba(255, 153, 0, 0.5);
}

/* Кнопка входу */
.login-form-button {
  background-color: #ff9900;
  border-color: #ff9900;
  color: white;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.login-form-button:hover {
  background-color: #e68a00;
  border-color: #e68a00;
}

/* Забули пароль та чекбокс */
.remember-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.forgot-password {
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Стилі для тексту створення акаунта */
.create-account {
  margin-top: 20px;
  font-size: 14px;
}

.create-account a {
  color: #6C63FF;
  text-decoration: none;
}

.create-account a:hover {
  text-decoration: underline;
}




h1 {
    font-size: 48px;
    color: white;
    margin-bottom: 10px;
    font-weight: 700;
}

p {
    margin-top: 1px;
    font-size: 18px;
    color: white;
    margin-bottom: 12px;
}

.form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

input[type="checkbox"] {
    margin-right: 10px;
}



button:hover {
    background-color: #f5a20a; /* Трохи яскравіший на ховер */
}


a:hover {
    text-decoration: underline;
}

.footer-text {
    color: white;
    margin-top: 50px;
}

.characters-left, .characters-right {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: auto;
}

.characters-left {
    left: 10px;
}

.characters-right {
    right: 10px;
}

h4 {
    margin-right: 92%;
}


/* Стиль для лівого зображення */
.img-left {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 150px; /* Ширина зображення може бути змінена під потреби */
  height: auto;
  z-index: 5; /* Забезпечує, що зображення буде зверху над іншими елементами */
}

/* Стиль для правого зображення */
.img-right {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 150px; /* Ширина зображення може бути змінена під потреби */
  height: auto;
  z-index: 5; /* Забезпечує, що зображення буде зверху над іншими елементами */
}

/* Щоб уникнути перекриття футером */
.footer {
  position: relative;
  z-index: 1; /* Робить футер нижчим за зображення, щоб уникнути перекриття */
  text-align: center;
  padding: 20px 0;
  /*background-color: #5D3FD3;*/
  color: white;
  width: 100%;
}




.logot {
  width: 40%;
    src: url('../img/mahaon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
}


.butterfly {
  position: absolute;
  width: 600px;
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.butterfly-1 {
  top: -300px;
  left: -313px;
  transform: rotate(10deg);
  background-image: url('../img/but1.png');
}


.butterfly-2 {
  margin-top: 56px;
  left: 164px;
  bottom: 15%;
  transform: rotate(-10deg);
  background-image: url('../img/but2.png');
  top: 283px;
      flex-shrink: 1;

}


@media (max-width: 768px) {
  .butterfly {
    width: 100px;
    width: 60px;
    height: 60px;
  }


}


#login {
  z-index: 10;
}

