.register-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img/login.jpeg');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.register-form-container {
  width: 400px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logot {
  width: 40%;
  background-image: url('../img/mahaon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}

h2 {
  color: white;
  margin-bottom: 20px;
}

.ant-form {
  width: 85%;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-input,
.ant-input-password {
  border-radius: 2px;
  padding: 10px 15px;
}

.register-button {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  background: #1a237e;
  border: none;
}

.social-login {
  z-index: 9999;
  margin-top: 20px;
  text-align: center;
  color: white;
}

.social-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.social-buttons .ant-btn {
  width: 48%;
  border-radius: 30px;
  background: white;
  color: #333;
}

.login-link {
  margin-top: 20px;
  color: white;
  text-align: center;
}

.login-link a {
  color: #4fc3f7;
}

.butterfly {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}

.butterfly-1 {
  width: 100%;
  height: 100%;
    top: -311px;
    left: -245px;
  transform: rotate(-15deg);
  background-image: url('../img/but1.png');
}

.butterfly-2 {
  flex-shrink: 1;
  background-image: url('../img/but2.png');
}

@media (max-width: 768px) {
  .register-form-container {
    width: 90%;
    padding: 20px;
  }

  .butterfly-1 {
    width: 150px;
    height: 150px;
    top: -40px;
    left: -20px;
  }

  .butterfly-2 {
    top: -20px;
    right: -10px;
  }
}

.ant-form-item-control-input-content {
  z-index: 366;
}
